/* eslint-disable @typescript-eslint/no-unused-vars */
import { EditMediaRefMethods } from "@app/components/main/media/edit/types";
import { useFujiGet } from "@app/hooks/useFuji";
import "@app/pages/main/media/edit/styles.scss";
import { MediaResponse } from "@fuji/media";
import React from "react";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { ExternalLinkOutline } from "@launchmetrics/lm-icons";
import { Button, Link, Loader, Tab, Tabs } from "@launchmetrics/lm-ui";

function MediaLayout() {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [buttonsState, setButtonsState] = React.useState<Record<string, boolean>>({
		isDisabled: true,
		isSubmitting: false
	});

	const {
		data: mediaResponse,
		isLoading,
		error
	} = useQuery<MediaResponse, Error>({
		queryKey: ["mediaLayout", params.mediaId],
		queryFn: () => useFujiGet<never, MediaResponse>(`/media/${params.mediaId}`)
	});

	const currentSection = React.useMemo(() => {
		const sectionPattern = /\/(pages|crawler|gallerySelectors)(\/|$)/;
		const match = location.pathname.match(sectionPattern);
		return match ? match[1] : "";
	}, [location]);

	const onChangeTab = (value: string) => {
		if (value !== currentSection) {
			navigate(value);
		}
	};

	const ref = React.useRef<EditMediaRefMethods>();
	const onCancel = () => {
		if (ref.current) {
			ref.current.onDiscardChanges();
		}
	};

	const onSave = () => {
		if (ref.current) {
			ref.current.onSaveChanges();
		}
	};

	if (isLoading) {
		return <Loader />;
	}

	if (error) {
		navigate("/404");
	}

	return (
		<div className="media">
			<div className="header">
				<span className="page-title">Edit Details</span>
				<span className="media-title">
					{mediaResponse?.name}{" "}
					<Link
						children={<ExternalLinkOutline />}
						href={`${process.env.REACT_APP_ORANGETOOL_URL}/media/edit#${mediaResponse?.id}`}
						target="_blank"
					/>
				</span>
				<span className="media-title">{}</span>
			</div>
			<div className="body">
				<Tabs value={currentSection} onSelected={(value: string) => onChangeTab(value)}>
					<Tab value="" label="Details" />
					<Tab value="pages" label="Pages" disabled />
					<Tab value="crawler" label="Crawler" />
					<Tab value="gallerySelectors" label="Gallery Selectors" disabled />
				</Tabs>
				<Outlet context={[ref, mediaResponse, setButtonsState]} />
			</div>
			<div className="footer">
				<div className="buttons">
					<Button variant="tertiary" children="Cancel" onClick={onCancel} disabled={buttonsState.isDisabled} />

					<Button
						variant="primary"
						children="Save Changes"
						onClick={onSave}
						disabled={buttonsState.isDisabled}
						rightIcon={buttonsState.isSubmitting ? <Loader /> : undefined}
					/>
				</div>
			</div>
		</div>
	);
}

export default MediaLayout;
