import reportWebVitals from "@app/reportWebVitals";
import router from "@app/routes";
import { FullStory, init } from "@fullstory/browser";
import { fullStoryIntegration } from "@sentry/fullstory";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "./index.css";

const stage = process.env.REACT_APP_STAGE!;
if (["staging", "production"].includes(stage)) {
	init({ orgId: process.env.REACT_APP_FULLSTORY_ORG_ID! });

	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [fullStoryIntegration(process.env.REACT_APP_SENTRY_ORG_SLUG!, { client: FullStory })],
		tracesSampleRate: 1.0,
		environment: stage
	});
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<>
		<RouterProvider router={router} />
	</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
